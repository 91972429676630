<template>
  <div class="contents-area not-found-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("PaymentSuccess") }}</h1>
          <Breadcrumbs />
        </div>
        <div class="order-info order-success-page">
          <div class="text-left">
            <p v-html="listSettings.payment_successSettings.seo_text"></p>
            <table width="100%" v-if="isOrderLoaded">
              <tbody>
                <tr>
                  <td>{{ translations("OrderNumber") }}</td>
                  <td>{{ listOrder.order_id }}</td>
                </tr>
                <tr>
                  <td>{{ translations("OrderFIO") }}</td>
                  <td>{{ listOrder.first_name }}</td>
                </tr>
                <tr>
                  <td>{{ translations("OrderPaymentMethod") }}</td>
                  <td>{{ paymentMethodName }}</td>
                </tr>
                <tr>
                  <td>{{ translations("OrderPaymentSum") }}</td>
                  <td>{{ numberFormat(listOrder.amount) }}&nbsp;₽</td>
                </tr>
              </tbody>
            </table>
            <p v-else>{{ translations("OrderNotSpecified") }}</p>
            <router-link to="/" class="btn btn-blue btn-big">{{
              translations("BackToHome")
            }}</router-link>
          </div>
          <div class="logo-right">
            <img
              :src="
                '//' + serverLink + '/upload/image/egrul/payment_success.svg'
              "
              alt="New Order"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, numberFormat } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentSuccess",
  components: {},
  data: () => ({
    serverLink: VUE_APP_BACK_URL,
    paymentMethodName: "",
  }),
  metaInfo() {
    return {
      title:
        this.listSettings.payment_successSettings.meta_title +
        " | " +
        this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.payment_successSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.payment_successSettings.meta_keywords,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listSettings",
      "listLang",
      "isOrderLoaded",
      "listOrder",
    ]),
  },
  async mounted() {
    await this.clearCart();

    const paymentNames = {
      raschSch: this.translations("ToBankAccount"),
      bankCard: this.translations("BankCard"),
      webMoney: this.translations("ToWebmoney"),
      youMoney: this.translations("ToYooMoney"),
      qiwi: this.translations("ToQiwi"),
      payPal: this.translations("ToPayPal"),
    };
    let orderId = this.$route.query.orderId;
    if (!orderId) {
      // orderId = - тут что-то еще
    }

    if (!this.isOrderLoaded && orderId) {
      await this.fetchOrder({
        lang: this.$cookies.get("user_language"),
        orderId,
      });
      this.paymentMethodName = paymentNames[this.listOrder.payment_method];
    } else {
      this.paymentMethodName = this.translations("OrderNotSpecified");
    }
  },
  methods: {
    ...mapActions(["fetchOrder", "clearCart"]),
    numberFormat,
  },
};
</script>